import { addWebp, fixImageUrl } from '@/utils/general';

export default function useWebp() {
  /********************
   * COMPOSITIONS      *
   ********************/
  const backendStore = useBackendStore();

  /********************
   * FUNCTIONS         *
   ********************/
  function fixImageUrlAndCheckWebp(img: string, webp: boolean = false) {
    if (!img) {
      return { url: null, contentType: 'image/jpg' };
    }
    let url = fixImageUrl(backendStore.url, img);
    let ext = img.split('.');
    ext = ext.pop();
    let contentType = `image/${ext}`;
    if (webp && url.toLowerCase().match(/\.jpe?g$/)) {
      url = addWebp(url);
      if (url.substring(url.length - 5) === '.webp') {
        contentType = 'image/webp';
      }
    }
    return { url, contentType };
  }

  return {
    fixImageUrlAndCheckWebp,
  };
}
